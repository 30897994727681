/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/pages/tables.scss";

.layout-menu-wrapper .menu-logo .app-name img {
    height: 45px;
    padding-left: 0px;
}

.p-link {
    color: #fff;
}

.layout-wrapper {
    background: none;
}

.layout-breadcrumb-right-items {
    padding-right: 40px;
}

.layout-rightmenu {
    width: 26rem;
}

.layout-menu-wrapper .layout-menu-container .layout-menu li.menu-separator {
    border-top: 1px solid #7e7f8e;
    padding: 1px 0 !important;
}

.exception-body .exception-footer .exception-appname {
    width: 200px;
}

.p-field {
    margin-top: 1rem;
}

td {
    max-width: 0px;
}

@media all and (max-width: 3004px) {
    .phoneNumber {
        display: none;
    }
}

.logo-bg {
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    width: 100%;
}

.p-avatar {
    text-transform: uppercase;
}

.layout-dashboard .p-avatar {
    display: inline-block !important;
}

.p-avatar img {
    object-fit: cover;
    object-position: center;
}

.layout-dashboard .p-avatar span {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin-top: 7px;
}

p-toolbar .p-mb-2 {
    margin-bottom: 0px !important;
}

.p-datatable.p-datatable:not(.p-datatable-gridlines) .p-datatable-tbody>tr>td {
    cursor: pointer;
}

.p-fluid .p-inline-message {
    margin-bottom: 15px;
}

.add-syllabus-entry {
    min-width: 120px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
}

.syllabus .number {
    width: 30px;
    float: left;
}

.syllabus .title {
    width: 68%;
    float: left;
}

.syallbus .actions {
    width: 30%;
    float: left;
}

.p-tree .p-tree-container .p-treenode {
    padding: 10px;
    border-radius: 10px;
}

.dark .p-tree .p-tree-container .p-treenode {
    background-color: #393a47;
    border: solid 1px #fff;
}

.light .p-tree .p-tree-container .p-treenode {
    background-color: #f1f1f5;
    border: solid 1px #393a47;
}

.p-treenode-label {
    width: 100%;
    padding-top: 10px;
}

.p-treenode-label p-button {
    float: right;
    padding-left: 10px;
}

.p-trenode .p-inputswitch {
    float: right;
    margin-top: 5px;
}

.p-fluid .p-inputnumber,
.p-fluid p-inputnumber:not(.w-full) {
    width: 95px !important;
    float: right;
    margin-right: 5px;
}

.w-full .p-inputnumber,
.w-full.p-inputnumber {
    width: 100% !important;
}

.p-fluid .p-inputnumber,
.p-fluid p-inputnumber {
    float: right;
    margin-right: 5px;
}

p-dialog .p-fluid .p-inputnumber,
p-dialog .p-fluid p-inputnumber {
    float: left;
}

.p-inputnumber-button-group .p-button-icon-only.p-inputnumber-button {
    padding: 0 !important;
    border: none !important;
}

#events button {
    margin-left: 5px;
}

#fc_frame,
#fc_frame.fc-widget-normal {
    bottom: 40px !important;
    right: 20px !important;
}

.p-datepicker {
    width: 310px !important;
}

.p-datepicker th {
    width: 30px !important;
}

/*Inscritos*/
.table-filters label {
    color: #fff;
    font-size: 16px;
}

@media (max-width: 900px) {
    .tittle-inscritos {
        display: none;
    }

}

.pd {
    font-size: 14px;
}

.block {
    display: block;
}

.p-treenode-dragover .p-treenode-droppoint {
    min-height: 15px;
    margin: 2px;
    border: dashed 2px #c1c1c1;
}

.file {
    overflow: hidden;
    text-overflow: ellipsis;
}


.spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    /* High z-index so it is on top of the page */
    top: 45%;
    right: 45%;
    /* or: left: 50%; */
    //margin-top: -..px;
    /* half of the elements height */
    //margin-right: -..px;
    /* half of the elements width */
}

.p-autocomplete-clear-icon {
    right: 6px;
}

.p-multiselect-clear-icon {
    right: 26px;
}


.p-float-label.p-input-number label {
    margin-top: 0.5rem;
}

.p-float-label.p-input-number>label {
    left: 1.6rem;
}

.p-float-label.p-input-number.p-input-number-array>label {
    left: 0.6rem;
}

.p-float-label.p-input-number p-inputnumber.p-inputwrapper-filled~label,
.p-float-label.p-input-number p-inputnumber.p-inputwrapper-focus~label,
.p-float-label.p-input-number.p-input-number-array p-inputnumber.p-inputwrapper-filled~label,
.p-float-label.p-input-number.p-input-number-array p-inputnumber.p-inputwrapper-focus~label {
    top: -1.6rem;
    font-size: 12px;
}

.p-float-label-custom {
    position: relative;
    display: inline;
    width: 100%;
}

.p-float-label-custom label {
    position: absolute;
    pointer-events: none;
    top: 65%;
    margin-top: -.7rem;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    font-size: 12px;
    left: 1.0rem;
    color: #868c9b;
}

.p-float-label-time {
    position: relative;
    display: inline-block;
    width: 100%;
}

.p-float-label-time label {
    position: absolute;
    pointer-events: none;
    margin-top: 0;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    font-size: 12px;
    left: 0.5rem;
    color: #868c9b;
}


.input-mask-calendar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-dialog-buttons {
    width: 97%;
    position: absolute;
    bottom: 10px;
    background: #252636;
    padding-top: 10px;
}

.schedule-Working-day {
    background: #1e1e1e
}

.custom-field-label {
    width: 100%;
    color: #868c9b;
    margin-left: .571rem;
    margin-bottom: .22rem;
    font-size: 12px;
    display: block;
}

.custom-password eyeicon {
    right: 0.571rem;
    color: #C8CCD8;
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}

/****Calendar****/
.non-working,
.date-calendar {
    border-radius: 3px;
    width: 70%;
    height: 70%;
    text-align: center; // Cambia esto para ajustarlo a tu estilo.

}

.new-request-day {
    border-radius: 3px;
    width: 70%;
    height: 70%;
    text-align: center;
    background-color: #d7d25f;
}

.non-working:hover {
    color: #7d7d7d;
}

.date-calendar:hover {
    color: #9b9b9b;
}

.date-custom {
    background-color: #285d28;
}

.date-bank-holiday {
    background-color: #b75f6f;
}

.date-local-bank-holiday {
    background-color: #bb617f;
}

.date-regional-bank-holiday {
    background-color: palevioletred;
}

.date-weekend {
    background-color: #fb3b3b;
}


.date-employee-holiday,
.date-employee-holiday:hover {
    background-color: rgb(58, 165, 112) !important;
}

.date-employee-sick-day,
.date-employee-sick-day:hover {
    background-color: rgb(72, 114, 168) !important;
}

.date-employee-sick-day {
    background-color: palevioletred;
}
.date-employee-pay-leave-day,
.date-employee-pay-leave-day:hover {
    background-color: #0e4892 !important;
}

.date-employee-pay-leave-day  {
    background-color: palevioletred;
}

.date-employee-personal-day {
    background-color: palevioletred;
}

.date-status-pending,
.date-status-pending:hover {
    background-color: rgb(180, 165, 32) !important;
}


.date-custom:hover {
    background-color: #214d21 !important;
}

.date-bank-holiday:hover {
    background-color: #a35563 !important;
}

.date-local-bank-holiday:hover {
    background-color: #bb617f !important;
}

.date-regional-bank-holiday:hover {
    background-color: #b35d79 !important;
}

.date-weekend:hover {
    background-color: #d33535 !important;
}


//FullCalendar
.fc-h-event.date-employee-holiday {
    border: 1px solid rgb(66, 224, 145);
}

.fc-event .date-weekend:hover {
    background-color: #959595 !important;
}

.fc .fc-bg-event {
    background: #959595 !important;
    opacity: var(--fc-bg-event-opacity);
}

.dark .fc-list-event-title:hover,
.dark .fc-event.fc-list-event:hover {
    color: gray;
}

@media (max-width: 1550px) {
    .p-column-lg {
        display: none !important;
    }
}

@media (max-width: 638px) {
    .p-column-md {
        display: none !important;
    }
}

.fc td {
    overflow: initial !important;
}